import { DataSourceRequestState, translateAggregateResults, translateDataSourceResultGroups } from '@progress/kendo-data-query';
import { isString } from 'lodash';

import { CustomGroupFilterRequest } from 'Services/DTOs/Grid/CustomGroupFilterRequest';
import { ExcelRequestDTO } from 'Services/DTOs/Grid/IExcel';
import { DataSourceResult, GridSimpleResult, GridResult, GroupData } from 'Services/DTOs/Grid/IGrid';
import { CustomGroupFilterDTO } from 'Services/DTOs/Grid/IPuzzle';
import { ResponseDTO } from 'Services/DTOs/IResponse';
import { isArrayOfGroupData } from 'Services/Utils/GridUtils';
import { toDataSourceRequest } from 'Services/Utils/KendoDataStateUtils';
import { WithPartialChanges } from 'Services/Utils/PartialChangesUtils';

import { IBackendGridService, IBackendGridServiceFactory } from './IBackendGridService';
import { IFetchAuthService } from './IFetchAuthService';
import { FormBody, IHttpService, QueryParams } from './IHttpService';

const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export class BackendGridServiceFactory implements IBackendGridServiceFactory
{
    #httpService: IHttpService;
    #fetchAuthService: IFetchAuthService;

    constructor(httpService: IHttpService, fetchAuthService: IFetchAuthService)
    {
        this.#httpService = httpService;
        this.#fetchAuthService = fetchAuthService;
    }

    get(transactionId: string) : IBackendGridService
    {
        return new BackendGridServiceImpl(transactionId, this.#httpService, this.#fetchAuthService);
    }
}

class BackendGridServiceImpl implements IBackendGridService
{
    #transactionId: string;
    #httpService: IHttpService;
    #fetchAuthService: IFetchAuthService;

    constructor(transactionId: string, httpService: IHttpService, fetchAuthService: IFetchAuthService)
    {
        this.#transactionId = transactionId;
        this.#httpService = httpService;
        this.#fetchAuthService = fetchAuthService;
    }

    async get<T>(urlOrGridDataState?: string | DataSourceRequestState, queryParamsOrCustomFilters?: QueryParams | Array<CustomGroupFilterDTO>): Promise<T | GridSimpleResult<T> | GridResult<T>>
    {
        if (isString(urlOrGridDataState))
        {
            const url = urlOrGridDataState as string;
            const queryParams = queryParamsOrCustomFilters as QueryParams;

            const { data } = await this.#httpService.get<ResponseDTO<T>>(`${this.#transactionId}/${url}`, queryParams);

            return data;
        }
        else
        {
            const gridDataState = urlOrGridDataState as DataSourceRequestState;
            const customFilters = queryParamsOrCustomFilters as Array<CustomGroupFilterDTO>;

            if (gridDataState != null)
            {
                return this._gridList(gridDataState, customFilters);
            }
            else
            {
                return this._simpleList() as Promise<GridSimpleResult<T>>;
            }
        }
    }

    async exportExcel<T>({ config, customGroupFilters, data, dataSource }: ExcelRequestDTO<T>): Promise<void>
    {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const body: FormBody = {
            config: {
                ...config,
                timeZone
            },
            data,
            dataSource: toDataSourceRequest(dataSource),
            customGroupFilters: customGroupFilters ? CustomGroupFilterRequest.build(customGroupFilters) : null // Only want key and customFilters
        };

        await this.download('ExportExcel', body);
    }

    async download(url: string, body?: FormBody): Promise<void>
    {
        const requestInit : RequestInit = {
            method: 'POST',
            body: body ? JSON.stringify(body) : undefined,
            headers: { 'accept': 'application/json', 'content-type': 'application/json' }
        };
        await this.#fetchAuthService.download(`${baseUrl}${this.#transactionId}/${url}`, requestInit);
    }

    // #region Grid data functions
    private async _simpleList<T>(): Promise<GridSimpleResult<T>>
    {
        const base_url = `${this.#transactionId}/Get`;
        const { data: json } = await this.#httpService.post<ResponseDTO<GridSimpleResult<T>>>(`${base_url}`);

        return {
            data: json.data,
            total: json.total
        } as GridSimpleResult<T>;
    }

    private async _gridList<T>(gridDataState: DataSourceRequestState, customFilters?: Array<CustomGroupFilterDTO>): Promise<GridSimpleResult<T> | GridResult<T>>
    {
        const hasGroups = gridDataState.group && gridDataState.group.length;
        const base_url = `${this.#transactionId}/GetGrid`;
        const customGroupFilters = customFilters ? CustomGroupFilterRequest.build(customFilters) : null; // Only want key and customFilters

        const dataSource = toDataSourceRequest(gridDataState);

        const { data: json } = await this.#httpService.post<ResponseDTO<DataSourceResult<T>>>(
            base_url,
            {
                dataSource,
                customGroupFilters
            });

        if (json.errors)
        {
            throw new Error(json.errors);
        }

        // FIXME: si hay datos aggregados entonces no devuelve array de T
        // si no: GroupResult[] con array de T dentro de las propiedades de Items.
        const data = hasGroups ? (translateDataSourceResultGroups(json.data) as Array<GroupData<T>>) : json.data;
        const total = json.total;
        const aggregate = json.aggregateResults ? translateAggregateResults(json.aggregateResults) : undefined;

        if (aggregate || isArrayOfGroupData(data))
        {
            return {
                data,
                total,
                aggregate
            };
        }
        else
        {
            return {
                data,
                total
            };
        }
    }
    // #endregion

    // #region CRUD functions
    async post<T>(url: string, data?: FormData): Promise<T>;
    async post<T>(url: string, data?: FormBody, queryParams?: QueryParams): Promise<T>
    {
        const { data: result } = await this.#httpService.post<ResponseDTO<T>>(`${this.#transactionId}/${url}`, data, queryParams);
        return result;
    }

    async put<T>(url: string, data?: FormBody, queryParams?: QueryParams): Promise<T>
    {
        const { data: result } = await this.#httpService.put<ResponseDTO<T>>(`${this.#transactionId}/${url}`, WithPartialChanges(data), queryParams);

        return result;
    }

    async delete<T>(url: string, queryParams?: QueryParams): Promise<T>
    {
        const { data } = await this.#httpService.delete<ResponseDTO<T>>(`${this.#transactionId}/${url}`, queryParams);

        return data;
    }
    // #endregion
}