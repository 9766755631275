import { sum } from 'lodash';
import { VoidFunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'Components/Core/Routes/Hooks/RouterHook';

import { DescargaVehiculoDTO, GraficaDescargaVehiculoDTO } from './I000000DTO';
import { DonutChartWidget } from './Widgets/DonutChartWidgetComponent';

interface IVehiculosDescargaChartProps
{
    vehiculos: Array<DescargaVehiculoDTO>;
}

export const VehiculosDescargaChart: VoidFunctionComponent<IVehiculosDescargaChartProps> = ({ vehiculos }) =>
{
    const { t } = useTranslation(['/FMT/I/I00/I000000/I000000'], { useSuspense: false });
    const navigate = useNavigate();

    const pendienteDescarga = vehiculos.filter(e => e.pendienteDescarga);
    const quedanCincoDias = vehiculos.filter(e => e.diasPendientes >= 85 && e.diasPendientes <= 90);
    const descargados = vehiculos.filter(e => !e.pendienteDescarga && e.diasPendientes < 85);

    const datosGrafica: Array<GraficaDescargaVehiculoDTO> = [
        {
            denominacionTipo: t('widgetVehiculos.pendienteDescarga'),
            numeroElementos: pendienteDescarga.length,
            color: '#C7001E',
        },
        {
            denominacionTipo: t('widgetVehiculos.descargados'),
            numeroElementos: descargados.length,
            color: '#53C700'
        },
        {
            denominacionTipo: t('widgetVehiculos.faltanCincoDias'),
            numeroElementos: quedanCincoDias.length,
            color: '#f8B85E'
        }
    ];

    const donutCenterRender = () => (
        <>
            <h5 className='totalVehiculos'>
                {sum(datosGrafica.map(e => e.numeroElementos))}
            </h5>
            <p>Total</p>
        </>
    );

    return (
        <DonutChartWidget<GraficaDescargaVehiculoDTO>
            title={t('widgetVehiculos.titulo')}
            className='vehiculos'
            data={datosGrafica}
            categoryField='denominacionTipo'
            field='numeroElementos'
            donutCenterRender={donutCenterRender}
            onClick={() => navigate('/V000000')}>
            <p className='contador'>
                {datosGrafica.find(e => e.denominacionTipo === t('widgetVehiculos.pendienteDescarga'))?.numeroElementos ?? 0}
            </p>
            <p className='titulo'> {t('widgetVehiculos.pendienteDescarga')} </p>
        </DonutChartWidget>
    );
};
VehiculosDescargaChart.displayName = 'VehiculosDescargaChart';
